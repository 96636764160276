<template>
  <v-container fluid>
    <v-row>
      <v-col class="custom-content-container pt-0" cols="12" md="9" sm="9">
        <v-row>
          <v-col md="8">
            <v-card flat class="custom-grey-border remove-border-radius h-100">
              <v-card-title class="headline grey lighten-4">
                <span class="font-weight-700 custom-headline color-custom-blue"
                  >Technician Address Information</span
                >
              </v-card-title>
              <v-card-text class="pa-6 font-size-16">
                <v-row>
                  <v-col md="6" lg="6">
                    <label class="font-size-16 font-weight-500 required"
                      >Country
                    </label>
                    <v-autocomplete
                      :items="countryList"
                      v-model="address.country_name"
                      hide-details
                      flat
                      :rules="[
                        validateRules.required(address.country_name, 'Country'),
                      ]"
                      solo
                      dense
                      item-value="value"
                      item-text="text"
                      placeholder="Country"
                      color="cyan"
                      item-color="cyan"
                      v-on:change="countryCheck($event)"
                      class="pt-0 mt-0"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{ item.text }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col md="6" lg="6">
                    <label class="font-size-16 font-weight-500"
                      >Search Address
                    </label>
                    <SearchAddress
                      variant="outlined"
                      density="compact"
                      hide-details
                      :country="address.country_name"
                      :country-code="country_code"
                      @update:address="(data) => setAddress(data)"
                      :placeholder="'Search Address'"
                    />
                  </v-col>
                </v-row>

                <!--  <template v-if="updateEngineer && pageLoading">
                      <v-skeleton-loader
                        class="custom-skeleton my-2 custom-text-height-40px"
                        type="text"
                      >
                      </v-skeleton-loader>
                    </template>
                    <template>
                      <v-hover v-slot="{ hover }">
                        <div class="custom-google-autocomplete">
                          <v-text-field
                            id="address-map"
                            v-model.trim="searchAddress"
                            append-icon="search"
                            dense
                            filled
                            label="Search Address"
                            solo
                            flat
                            color="cyan"
                            :loading="autocompleteLoading"
                            v-on:keyup="getGoogleAddress"
                            v-on:blur="isFocused = false"
                            v-on:focus="isFocused = true"
                          >
                          </v-text-field>
                          <v-list
                            v-if="(hover || isFocused) && autocompleteList.length"
                            two-line
                            elevation="4"
                            class="custom-google-autocomplete-list"
                          >
                            <v-list-item
                              v-for="(address, index) in autocompleteList"
                              :key="index"
                              link
                              v-on:click="selectGoogleAddress(index)"
                            >
                              <v-list-item-icon class="m-0 mr-3 my-auto">
                                <v-icon color="cyan"> mdi-map-marker </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content
                                class="py-1"
                                v-if="
                                  lodash.isEmpty(address.structured_formatting) ===
                                  false
                                "
                              >
                                <v-list-item-title class="font-size-14">{{
                                  address.structured_formatting.main_text
                                }}</v-list-item-title>
                                <v-list-item-subtitle class="font-size-12">{{
                                  address.structured_formatting.secondary_text
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-content class="py-1" v-else>
                                <v-list-item-title class="font-size-14">{{
                                  address.description
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </v-hover>
                    </template> -->
                <template v-if="address.country_name == 'singapore'">
                  <template v-if="updateEngineer && pageLoading">
                    <v-skeleton-loader
                      class="custom-skeleton my-2 custom-text-height-40px"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <label class="font-weight-700 font-size-16">Unit no.</label>
                    <v-text-field
                      v-model.trim="address.unit_no"
                      :rules="[
                        validateRules.minLength(address.unit_no, 'Unit no.', 1),
                        validateRules.maxLength(
                          address.unit_no,
                          'Unit no.',
                          255
                        ),
                      ]"
                      dense
                      filled
                      placeholder="Unit no."
                      solo
                      flat
                    ></v-text-field>
                  </template>

                  <template v-if="updateEngineer && pageLoading">
                    <v-skeleton-loader
                      class="custom-skeleton my-2 custom-text-height-40px"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <label class="font-weight-700 font-size-16"
                      >Address Line 1</label
                    >
                    <v-text-field
                      v-model.trim="address.street_1"
                      dense
                      filled
                      placeholder="Address Line 1"
                      solo
                      flat
                    ></v-text-field>
                  </template>

                  <template v-if="updateEngineer && pageLoading">
                    <v-skeleton-loader
                      class="custom-skeleton my-2 custom-text-height-40px"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <label class="font-weight-700 font-size-16"
                      >Address Line 2</label
                    >
                    <v-text-field
                      v-model.trim="address.street_2"
                      dense
                      filled
                      placeholder="Address Line 2"
                      solo
                      flat
                    ></v-text-field>
                  </template>

                  <template v-if="updateEngineer && pageLoading">
                    <v-skeleton-loader
                      class="custom-skeleton my-2 custom-text-height-40px"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <label class="font-weight-700 font-size-16"
                      >Postal Code</label
                    >
                    <v-text-field
                      v-model.trim="address.postal_code"
                      dense
                      filled
                      placeholder="Postal Code"
                      solo
                      flat
                    ></v-text-field>
                  </template>
                </template>
                <template v-else>
                  <label class="font-size-16 font-weight-500 required"
                    >Full Address</label
                  >
                  <v-textarea
                    v-model.trim="address.street_1"
                    :rules="[
                      validateRules.required(address.street_1, 'Full Address'),
                      validateRules.maxLength(
                        address.street_1,
                        'Full Address',
                        255
                      ),
                    ]"
                    auto-grow
                    dense
                    filled
                    placeholder="Full Address"
                    solo
                    flat
                    row-height="15"
                    color="cyan"
                  ></v-textarea>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="custom-sidebar-container white lighten-3"
        cols="12"
        md="3"
        sm="3"
      >
        <template v-for="(fieldInfo, indx) in fieldDescriptions">
          <div
            v-if="fieldInfo.field == currentActiveField"
            :key="indx"
            class="help-sidebar"
          >
            <div class="help-sidebar-header">
              <h3 v-if="fieldInfo.title" class="section-header-title">
                {{ fieldInfo.title }}
              </h3>
            </div>
            <p v-if="fieldInfo.description" class="help-sidebar-description">
              {{ fieldInfo.description }}
            </p>
            <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
              {{ fieldInfo.footer }}
            </h5>
            <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
              {{ fieldInfo.footer_content }}
            </p>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { EngineerEventBus } from "@/core/lib/engineer/engineer.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { QUERY } from "@/core/services/store/request.module";
import SearchAddress from "@/view/components/Search-Address";

export default {
  name: "Engineer",
  mixins: [ValidationMixin, CommonMixin],
  props: {
    pageLoading: {
      type: Boolean,
      default: false,
    },
    updateEngineer: {
      type: Boolean,
      default: false,
    },
    engineer: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      timeoutPreLimit: 500,
      timeoutPre: null,
      isFocused: false,
      timeoutLimit: 500,
      timeout: null,
      trackGPS: false,
      autocompleteLoading: false,
      autocompleteList: new Array(),
      searchAddress: null,
      address: {
        street_1: null,
        street_2: null,
        unit_no: null,
        postal_code: null,
        // country_name: null,
        country_name: "singapore",
      },
      fieldDescriptions: [],
      country_code: "sg",
      countryList: [
        {
          text: "Singapore",
          value: "singapore",
          country_code: "sg",
        },
        {
          text: "Malaysia",
          value: "malaysia",
          country_code: "my",
        },
        {
          text: "Myanmar",
          value: "myanmar",
          country_code: "mm",
        },
        {
          text: "India",
          value: "india",
          country_code: "in",
        },
        {
          text: "China",
          value: "china",
          country_code: "cn",
        },
        {
          text: "Australia",
          value: "australia",
          country_code: "at",
        },
        {
          text: "American",
          value: "american",
          country_code: "as",
        },
        {
          text: "UK",
          value: "uk",
          country_code: "gb",
        },
        {
          text: "Canada",
          value: "canada",
          country_code: "ca",
        },
      ],
    };
  },
  watch: {
    address: {
      deep: true,
      immediate: true,
      handler(param) {
        const _this = this;
        clearTimeout(_this.timeoutPre);
        _this.timeoutPre = setTimeout(function () {
          EngineerEventBus.$emit("update:address", param);
        }, _this.timeoutPreLimit);
      },
    },
    engineer() {
      this.setEngineerAddress();
    },
  },
  methods: {
    countryCheck(data) {
      let code = "";
      code = this.lodash.find(this.countryList, function (row) {
        if (row.value == data) {
          return row.country_code;
        } else {
          return false;
        }
      });
      this.country_code = code.country_code;
      this.address.postal_code = null;
      this.address.street_1 = null;
      this.address.street_2 = null;
      this.address.unit_no = null;
      // this.address.longitude = null;
      // this.address.latitude = null;
    },
    setAddress(e) {
      this.address.postal_code = this.lodash.cloneDeep(e.postal_code);
      this.address.street_1 = e.address1;
      this.address.street_2 = e.address2;
      this.address.unit_no = e.unit_number;
      // this.address.longitude = e.longitude;
      // this.address.latitude = e.latitude;
    },
    setEngineerAddress() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.engineer) === false) {
        _this.address.street_1 = _this.engineer.address_line_1;
        _this.address.street_2 = _this.engineer.address_line_2;
        _this.address.unit_no = _this.engineer.unit_number;
        _this.address.postal_code = _this.engineer.postal_code;
        _this.address.country_name = _this.engineer.country_name;
        let code = "";
        code = this.lodash.find(this.countryList, function (row) {
          if (row.value == _this.engineer.country_name) {
            return row.country_code;
          } else {
            return false;
          }
        });
        this.country_code = code.country_code;
      }
    },
    selectGoogleAddress(index) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.autocompleteList[index]) === false) {
        let place_id = _this.autocompleteList[index].place_id;
        _this.autocompleteLoading = true;
        _this.$store
          .dispatch(QUERY, {
            url: "google-api/place-detail",
            data: { "place-id": place_id },
          })
          .then((response) => {
            _this.formatAddress(response.data);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.clearAddress();
            _this.autocompleteList = new Array();
            _this.autocompleteLoading = false;
          });
      }
    },
    getGoogleAddress() {
      const _this = this;
      try {
        if (_this.searchAddress) {
          _this.trackGPS = false;
          _this.autocompleteLoading = true;
          clearTimeout(_this.timeout);
          _this.autocompleteList = new Array();
          _this.timeout = setTimeout(function () {
            _this.$store
              .dispatch(QUERY, {
                url: "google-api/autocomplete",
                data: { search: _this.searchAddress },
              })
              .then((response) => {
                _this.autocompleteList = response.data;
              })
              .catch((error) => {
                _this.logError(error);
              })
              .finally(() => {
                _this.autocompleteLoading = false;
              });
          }, _this.timeoutLimit);
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    clearAddress() {
      const _this = this;
      _this.searchAddress = null;
      _this.address.street_1 = null;
      _this.address.street_2 = null;
      _this.address.unit_no = null;
      _this.address.postal_code = null;
      //_this.address.country_name = null;
    },
    formatAddress(param) {
      const _this = this;
      try {
        _this.autocompleteLoading = true;
        _this
          .formatGPSAddress(param)
          .then((result) => {
            if (_this.lodash.isEmpty(param) === false) {
              _this.searchAddress = null;
              _this.address.street_1 = result.street_1;
              _this.address.street_2 = result.street_2;
              _this.address.unit_no = result.unit_no;
              _this.address.postal_code = result.zip_code;
              //_this.address.country_name = result.country_name;
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.autocompleteLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },
  },
  components: {
    SearchAddress,
  },
  mounted() {
    const _this = this;
    _this.setEngineerAddress();
  },
};
</script>
